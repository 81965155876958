@import url("https://fonts.googleapis.com/css?family=Nunito:200,300,400,400i,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 1024px) and (max-width: 1280px) {
  html {
    font-size: 14px !important;
  }
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1.15;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  //background-color: #f8fafc;
  @apply font-sans;
}

::-webkit-scrollbar {
  content: "";
  width: 0.25rem;
  height: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background-color: #cde1e7;
  border-radius: 9999rem;
  border-radius: 1.563rem;
  height: 1.25rem;
}

.bg-login {
  background: linear-gradient(180deg, rgba(55, 59, 90, 0) 0%, #31355c 100%),
    url("https://i.imgur.com/tdPsT4g.jpg"), #c4c4c4;
  background-size: auto 110%;
  background-position: -19.375rem;
}

.lineHorizontal {
  display: flex;
  color: #7e819f;
  size: 0.875rem;
  flex-direction: row;
}

.lineHorizontal:after,
.lineHorizontal:before {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #c4daf2;
  margin: auto;
}

.social-button {
  border: 1px solid rgba(114, 124, 148, 0.5);
  border-radius: 0.5rem;
  width: 12.688rem;
  color: #727c94;
  height: 2.438rem;
  width: 12.688rem;
  padding: 0.5rem;
  font-size: 0.9rem;
  align-items: center;
}
